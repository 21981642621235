<template>
    <b-card title="Create Partner">
        <validation-observer ref="simpleRules">
            <b-form @submit.prevent>
                <b-row>
                    <!--Company Name -->
                    <b-col cols="12">
                        <b-form-group label="Company Name" label-for="Company Name">
                            <validation-provider name="Company Name" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="title" v-model="item.title"
                                        :state="errors.length > 0 ? false : null" placeholder="Company Name" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!--Company Name -->
                    <!-- <b-col cols="12">
                        <b-form-group label="Select Company" label-for="company">
                            <validation-provider name="Company" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="MapPinIcon" />
                                    </b-input-group-prepend>
                                    <b-form-select id="company" v-model="item.title" :options="companyOptions"
                                        :state="errors.length > 0 ? false : null">
                                    </b-form-select>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col> -->

                    <!-- Company image Preview -->

                    <b-col cols="12">
                        <b-img thumbnail fluid :src="previewLogoImg" :class="previewLogoImg.length <= 0 ? 'd-none' : null"
                            alt="Image"></b-img>
                    </b-col>

                    <!-- Company Logo -->
                    <b-col cols="12">
                        <b-form-group label="Company Logo" label-for="Company Logo">
                            <validation-provider name="Company Logo" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="ImageIcon" />
                                    </b-input-group-prepend>
                                    <b-form-file id="CompanyImg" v-model="CompanyImg"
                                    @change="onComLogoChange"
                                        :state="errors.length > 0 ? false : null"
                                        placeholder="Choose a file or drop it here..."
                                        drop-placeholder="Drop file here..." />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Profile Preview -->

                    <b-col cols="12">
                        <b-img thumbnail fluid :src="previewHeroImg" :class="previewHeroImg.length <= 0 ? 'd-none' : null"
                            alt="Image"></b-img>
                    </b-col>

                    <!-- Company Hero Image -->
                    <b-col cols="12">
                        <b-form-group label="Company Hero Image" label-for="Company Hero Image">
                            <validation-provider name="Company Hero Image" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="ImageIcon" />
                                    </b-input-group-prepend>
                                    <b-form-file id="companyHeroImg" v-model="companyHeroImg"
                                    @change="onComHeroChange"
                                        :state="errors.length > 0 ? false : null"
                                        placeholder="Choose a file or drop it here..."
                                        drop-placeholder="Drop file here..." />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Facebook Url -->
                    <b-col cols="12">
                        <b-form-group label="Facebook Url" label-for="Facebook Url">
                            <validation-provider name="Facebook Url" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="facebook" v-model="item.facebook"
                                        :state="errors.length > 0 ? false : null" placeholder="Facebook Url" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Instagram Url -->
                    <b-col cols="12">
                        <b-form-group label="Instagram Url" label-for="Instagram Url">
                            <validation-provider name="Instagram Url" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="instagram" v-model="item.instagram"
                                        :state="errors.length > 0 ? false : null" placeholder="Instagram Url" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Twitter Url -->
                    <b-col cols="12">
                        <b-form-group label="Twitter Url" label-for="Twitter Url">
                            <validation-provider name="Twitter Url" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="twiter" v-model="item.twiter"
                                        :state="errors.length > 0 ? false : null" placeholder="Twitter Url" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group label="Status" label-for="Status">
                            <validation-provider name="Status" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="MapPinIcon" />
                                    </b-input-group-prepend>
                                    <b-form-select id="publish" v-model="item.type" :options="options"
                                        :state="errors.length > 0 ? false : null">
                                    </b-form-select>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <h6>Second Section</h6>

                    <!-- Profile Preview -->

                    <b-col cols="12">
                        <b-img thumbnail fluid :src="previewProfileImg" :class="previewProfileImg.length <= 0 ? 'd-none' : null"
                            alt="Image"></b-img>
                    </b-col>

                    <!-- Profile Image -->
                    <b-col cols="12">
                        <b-form-group label="Profile Image" label-for="profile">
                            <validation-provider name="Profile Image" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="ImageIcon" />
                                    </b-input-group-prepend>
                                    <b-form-file id="profileImg" v-model="profileImg"
                                    @change="onComProfileChange"
                                        :state="errors.length > 0 ? false : null"
                                        placeholder="Choose a file or drop it here..."
                                        drop-placeholder="Drop file here..." />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- About -->
                    <b-col cols="12">
                        <b-form-group label="About" label-for="About">
                            <validation-provider name="About" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>
                                    <b-form-textarea id="about" v-model="item.about"
                                        :state="errors.length > 0 ? false : null" placeholder="Enter something..." rows="5"
                                        size="lg" max-rows="10"></b-form-textarea>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <h6>Third Section</h6>

                    <b-col cols="12">
                        <b-img thumbnail fluid :src="previewThirdSectionImg"
                            :class="previewThirdSectionImg.length <= 0 ? 'd-none' : null" alt="Image"></b-img>
                    </b-col>

                    <!-- Upload Hero Image For Third Section -->
                    <b-col cols="12">
                        <b-form-group label="Upload Hero Image For Third Section"
                            label-for="Upload Hero Image For Third Section">
                            <validation-provider name="Upload Hero Image For Third Section" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="ImageIcon" />
                                    </b-input-group-prepend>
                                    <b-form-file id="HeroImageForThirdSection" v-model="HeroImageForThirdSection"
                                    @change="onComThirdSectionChange"
                                        :state="errors.length > 0 ? false : null"
                                        placeholder="Choose a file or drop it here..."
                                        drop-placeholder="Drop file here..." />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- email -->
                    <b-col cols="12">
                        <b-form-group label="Email" label-for="fh-email">
                            <validation-provider name="Email" rules="required|max:50|email|unique" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="MailIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input id="fh-email" v-model="item.email" type="email"
                                        :state="errors.length > 0 ? false : null" placeholder="Email" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <b-form-text class="text-danger">
                                Enter a Valid Email
                            </b-form-text>
                        </b-form-group>
                    </b-col>

                    <!-- Phone -->
                    <b-col cols="12">
                        <b-form-group label="Phone" label-for="Phone">
                            <validation-provider name="Phone" rules="required|max:10" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input id="phone" v-model="item.phone" :state="errors.length > 0 ? false : null"
                                        placeholder="Phone" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Country -->
                    <b-col cols="12">
                        <b-form-group label="Select Country" label-for="country">
                            <validation-provider name="Country" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="MapPinIcon" />
                                    </b-input-group-prepend>
                                    <b-form-select id="country" v-model="item.country" :options="countryOptions"
                                        :state="errors.length > 0 ? false : null">
                                    </b-form-select>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Address -->
                    <b-col cols="12">
                        <b-form-group label="Address" label-for="Address">
                            <validation-provider name="Address" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>
                                    <b-form-textarea id="address" v-model="item.address"
                                        :state="errors.length > 0 ? false : null" placeholder="Enter something..." rows="5"
                                        size="lg" max-rows="10"></b-form-textarea>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Opening Hours -->
                    <b-col cols="12">
                        <b-form-group label="Opening Hours" label-for="Opening Hours">
                            <validation-provider name="Sunday" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="days mb-2" prepend="Sunday"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <!-- <b-form-input value = "Sunday" readonly /> -->
                                    <b-form-input id="sunday" v-model="item.sunday"
                                        :state="errors.length > 0 ? false : null" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <validation-provider name="Monday" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="days mb-2" prepend="Monday"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <!-- <b-form-input  value = "Monday" readonly /> -->
                                    <b-form-input id="monday" v-model="item.monday"
                                        :state="errors.length > 0 ? false : null" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <validation-provider name="Tuesday" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="days mb-2" prepend="Tuesday"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <!-- <b-form-input  value = "Tuesday" disabled /> -->
                                    <b-form-input id="tuesday" v-model="item.tuesday"
                                        :state="errors.length > 0 ? false : null" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <validation-provider name="Wednesday" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="days mb-2" prepend="Wednesday"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <!-- <b-form-input  value = "Wednesday" disabled /> -->
                                    <b-form-input id="wednesday" v-model="item.wednesday"
                                        :state="errors.length > 0 ? false : null" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <validation-provider name="Thursday" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="days mb-2" prepend="Thursday"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <!-- <b-form-input  value = "Thursday" disabled /> -->
                                    <b-form-input id="thursday" v-model="item.thursday"
                                        :state="errors.length > 0 ? false : null" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <validation-provider name="Friday" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="days mb-2" prepend="Friday"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <!-- <b-form-input  value = "Friday" disabled /> -->
                                    <b-form-input id="friday" v-model="item.friday"
                                        :state="errors.length > 0 ? false : null" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <validation-provider name="Saturday" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="days mb-2" prepend="Saturday"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <!-- <b-form-input  value = "Saturday" disabled /> -->
                                    <b-form-input id="saturday" v-model="item.saturday"
                                        :state="errors.length > 0 ? false : null" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Meta Title -->
                    <b-col cols="12">
                        <b-form-group label="Meta Title" label-for="meta title">
                            <validation-provider name="meta_title" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="meta_title" v-model="item.meta_title"
                                        :state="errors.length > 0 ? false : null" placeholder="Meta Title" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Meta Description -->
                    <b-col cols="12">
                        <b-form-group label="Meta Description" label-for="meta description">
                            <validation-provider name="meta_description" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>

                                    <b-form-textarea id="meta_description" v-model="item.meta_description"
                                        placeholder="Enter something..." rows="5" size="lg" max-rows="10">
                                    </b-form-textarea>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Meta Keyword -->
                    <b-col cols="12">
                        <b-form-group label="Meta Keyword" label-for="meta Keyword">
                            <validation-provider name="meta_keywords" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="meta_keywords" v-model="item.meta_keywords"
                                        :state="errors.length > 0 ? false : null" placeholder="Meta Keyword" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>


                    <!-- submit and reset -->
                    <b-col>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" @click.prevent="validationForm"
                            variant="primary" class="mr-1">
                            Submit
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                            @click="reset">
                            Reset
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormText,
    BFormDatalist,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
//Database
import { db, storage } from "@/main";
import firebase from "firebase";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//Vee-Validate 
import { ValidationProvider, ValidationObserver, validate } from 'vee-validate';
import {
    required
} from '@validations'
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BFormCheckbox,
        BForm,
        BFormText,
        BButton,
        BFormDatalist,
        BFormInvalidFeedback,
        BFormValidFeedback,
        BFormFile,
        BFormSelect,
        BFormTextarea,
        BImg,
        ValidationProvider,
        ValidationObserver,
        validate,
        required,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            CompanyImg: '',
            companyHeroImg: '',
            profileImg: '',
            HeroImageForThirdSection: '',
            item: {},
            options: [],
            imageData: null,
            picture: null,
            uploadValue: 0,
            imageDatach: null,
            picturech: null,
            uploadValuech: 0,
            imageDatachssi: null,
            picturechssi: null,
            slugurl: '',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
            email: '',
            phone: '',
            address: '',
            title: '',
            about: '',
            facebook: '',
            instagram: '',
            twiter: '',
            checkedNames: '',
            description: '',
            previewLogoImg: '',
            previewHeroImg: '',
            previewProfileImg: '',
            previewThirdSectionImg: '',
            previewImg: '',
            meta_title: '',
            meta_description: '',
            meta_keywords: '',
            type: null,
            selectedCountry: null,
            options: [
                { 'value': null, text: 'Please select Publish / Unpblish' },
                { 'value': 0, 'text': 'Unpublished' },
                { 'value': 1, 'text': 'published' }
            ],
            companyOptions: [
                { 'value': null, text: 'Please select a company' },
            ],
            countryOptions: [
                { 'value': null, text: 'Please select a country' },
            ],
        }
    },
    created() {
        this.getitem();
        this.getCompany();
        this.getCountries();
    },
    computed: {
        validation() {
            return this.name.length > 4 && this.name.length < 13
        },
    },
    methods: {
        getCountries() {
            this.countries = [];
            db.collection("countries")
                .get()
                .then((querySnapshot) => {
                    //console.log('hhhh1');
                    querySnapshot.forEach((doc) => {
                        this.countryOptions.push({
                            value: doc.id,
                            text: doc.data().country,
                            slug: doc.data().slug,
                        });
                        console.log(doc.id, " => ", doc.data());
                    });
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        },

        addFind: function () {
            this.finds.push({ value: '' });
        },
        deleteFind: function (index) {
            console.log(index);
            console.log(this.finds);
            this.finds.splice(index, 1);
        },

        getitem() {
            var id = this.$route.params.id;

            db.collection('partner').doc(id).get().then((doc) => {

                this.item = doc.data();
                //alert(this.item.tuesday);
                console.log(doc.data())
                this.previewLogoImg = doc.data().company_image;
                this.previewHeroImg = doc.data().company_hero_image;
                this.previewProfileImg = doc.data().image;
                this.previewThirdSectionImg = doc.data().hero_image_third_section;
                // this.ty = doc.data().type;

            })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });


        },

        reset() {
            this.sunday = '',
                this.monday = '',
                this.tuesday = '',
                this.wednesday = '',
                this.thursday = '',
                this.friday = '',
                this.saturday = '',
                this.email = '',
                this.phone = '',
                this.address = '',
                this.title = '',
                this.about = '',
                this.facebook = '',
                this.instagram = '',
                this.twiter = '',
                this.checkedNames = '',
                this.description = '',
                this.previewLogoImg = '',
                this.previewHeroImg = '',
                this.previewProfileImg = '',
                this.previewThirdSectionImg = '',
                this.previewImg = '',
                this.meta_title = '',
                this.meta_description = '',
                this.meta_keywords = '',
                this.profileImg = '',
                this.CompanyImg = '',
                this.companyHeroImg = '',
                this.HeroImageForThirdSection = '',
                this.type = null,
                this.selectedCountry = null;
        },

        getCompany() {
            this.companies = [];
            db.collection("companies")
                .get()
                .then((querySnapshot) => {
                    //console.log('hhhh1');
                    querySnapshot.forEach((doc) => {
                        this.companyOptions.push({
                            value: doc.id,
                            text: doc.data().name,
                            // slug: doc.data().slug,
                        });
                        console.log(doc.id, " => ", doc.data().name);
                    });
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        },

        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    // alert('form submitted!')
                    this.submit();
                }
            })
        },

        submit() {
            store.dispatch('app/commitActivateLoader');
            // if(this.checkedNames == true){
            // this.blogshowoption = 1;
            // }else if(this.checkedNames == false){
            // this.blogshowoption = 0;
            // } 
            var theSlug = this.item.title;
            theSlug = theSlug.toLowerCase();
            theSlug = theSlug.replace(/\s+/g, '-');
            // Replace Ampersand With Dash and Spaces
            theSlug = theSlug.replace(/&/g, "-and-")
            this.slugurl = theSlug;
            // alert(this.blogshowoption)
            var id = this.$route.params.id;
            var date = new Date().toLocaleString();//.toISOString().slice(0, 10);

            db.collection('countries').doc(this.item.country)
                .get().then(snapshot => {
                    // alert(snapshot.data().country)
                    this.country_name = snapshot.data().country

                    db.collection("partner")
                        .doc(id)
                        .update({
                            title: this.item.title,
                            slugurl: this.slugurl,
                            facebook: this.item.facebook,
                            instagram: this.item.instagram,
                            twiter: this.item.twiter,
                            email: this.item.email,
                            about: this.item.about,
                            address: this.item.address,
                            country: this.item.country,
                            country_name: this.country_name,
                            phone: this.item.phone,
                            sunday: this.item.sunday,
                            monday: this.item.monday,
                            tuesday: this.item.tuesday,
                            wednesday: this.item.wednesday,
                            thursday: this.item.thursday,
                            friday: this.item.friday,
                            saturday: this.item.saturday,
                            type: parseInt(this.item.type),
                            meta_title: this.item.meta_title,
                            meta_description: this.item.meta_description,
                            meta_keywords: this.item.meta_keywords,
                            created: date,
                            modified: date,
                        })
                        .then(async (doc) => {
                            try {
                                console.log("Added Successfully!");
                                const files = [
                                    { name: this.profileImg.name, file: this.profileImg, fieldName: 'image' },
                                    { name: this.HeroImageForThirdSection.name, file: this.HeroImageForThirdSection, fieldName: 'hero_image_third_section' },
                                    { name: this.companyHeroImg.name, file: this.companyHeroImg, fieldName: 'company_hero_image' },
                                    { name: this.CompanyImg.name, file: this.CompanyImg, fieldName: 'company_image' }
                                ];
                                console.log("files =>", files);
                                for (const fileObj of files) {
                                    const filename = fileObj.name;
                                    if (filename) {
                                        const storageRef = storage.ref();
                                        const fileRef = storageRef.child('partner' + '/' + id + "_" + filename);
                                        const result = await fileRef.put(fileObj.file);
                                        const imageUrl = await fileRef.getDownloadURL();
                                        console.log(imageUrl);
                                        await db.collection("partner").doc(id).update({ [fileObj.fieldName]: imageUrl });
                                    }
                                }

                                this.showNotification('Partner Updated Successfully!', 'AlignJustifyIcon', 'success');
                                store.dispatch('app/commitDeactivateLoader');
                                this.$router.push({ name: 'partner-index' });
                            } catch (error) {
                                console.error("Error writing document: ", error);
                                this.showNotification('Error! Request Failed!', 'AlignJustifyIcon', 'danger');
                            }
                        })
                })
        },
        showNotification(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    variant,
                },
            })
        },

        onComLogoChange(event) {
            validate(event.target.files[0], 'required|image|size:100', {
                name: 'Company Logo Image',
            }).then(result => {
                if (result.valid) {
                    const fileReader = new FileReader();

                    fileReader.onload = (event) => {
                        this.previewLogoImg = event.target.result;
                    }
                    fileReader.readAsDataURL(event.target.files[0]);
                } else {
                    this.previewLogoImg = '';
                }
            });
        },

        onComHeroChange(event) {
            validate(event.target.files[0], 'required|image|size:100', {
                name: 'Company Background Image',
            }).then(result => {
                if (result.valid) {
                    const fileReader = new FileReader();

                    fileReader.onload = (event) => {
                        this.previewHeroImg = event.target.result;
                    }
                    fileReader.readAsDataURL(event.target.files[0]);
                } else {
                    this.previewHeroImg = '';
                }
            });
        },

        onComProfileChange(event) {
            validate(event.target.files[0], 'required|image|size:100', {
                name: 'Company Background Image',
            }).then(result => {
                if (result.valid) {
                    const fileReader = new FileReader();

                    fileReader.onload = (event) => {
                        this.previewProfileImg = event.target.result;
                    }
                    fileReader.readAsDataURL(event.target.files[0]);
                } else {
                    this.previewProfileImg = '';
                }
            });
        },

        onComThirdSectionChange(event) {
            validate(event.target.files[0], 'required|image|size:100', {
                name: 'Company Background Image',
            }).then(result => {
                if (result.valid) {
                    const fileReader = new FileReader();

                    fileReader.onload = (event) => {
                        this.previewThirdSectionImg = event.target.result;
                    }
                    fileReader.readAsDataURL(event.target.files[0]);
                } else {
                    this.previewThirdSectionImg = '';
                }
            });
        },
    },
}
</script>
<style scoped>
.days>.input-group-prepend>.input-group-text {
    width: 500px;
    background-color: #dddddd !important;
}
</style>